<template>
  <el-container direction="vertical">
    <el-header class="header">
      <el-row type="flex" align="middle">
        <!-- <label class="title">盛魁会展</label> -->
        <el-divider direction="vertical"></el-divider>
        <label class="title">展会管理系统</label>
      </el-row>
    </el-header>
    <el-main class="content">
      <div class="bg">
        <div class="info">
          <!-- <img src="../../assets/img/login-title.png"> -->
        </div>
        <div class="login-box">
          <div class="title">
            <label>登录</label>
            <label class="welcome">WELCOME</label>
          </div>
          <form class="login-form">
            <div class="tips" v-show="loginTips">
              <font-awesome-icon fas icon="minus-circle"></font-awesome-icon>{{ loginTips }}
            </div>
            <el-input v-model="form.username" placeholder="手机 / 用户名">
              <template slot="prepend">
                <font-awesome-icon fas icon="user"></font-awesome-icon>
              </template>
            </el-input>
            <el-input :type="passwordCss.type" v-model="form.password" placeholder="请输入登录密码">
              <template slot="prepend">
                <font-awesome-icon fas :icon="passwordCss.icon" @click="pwdVisiable = !pwdVisiable">
                </font-awesome-icon>
              </template>
            </el-input>
            <div class="type-box">
              <el-checkbox v-model="form.markUsername">记住账号</el-checkbox>
            </div>
            <el-button round @click="login" type="primary" class="login-button">
              <span v-if="!logining">登录</span>
              <span v-else>正在登陆<font-awesome-icon far icon="spinner" spin></font-awesome-icon></span>
            </el-button>
          </form>
        </div>
      </div>
    </el-main>
    <el-footer class="buttom" height="">
      <div class="copy-right">
        <!-- <div>Copyright &copy; 2021 -  Shengkui Technology Co.,Ltd. All Rights Reserved.
        </div> -->
        <div class="copy">
          <el-link :underline="false" href="http://beian.miit.gov.cn">京ICP备2021008938号</el-link>
        </div>
      </div>
    </el-footer>
  </el-container>
</template>

<script>
import md5 from 'js-md5'
import API from '../../apis/ticket-api'
import { LOGIN, INDEX } from '../../router/base-router'
import { SET_TOKEN, CLEAR_TOKEN, CLEAR_LOGIN } from '../../store/mutation-types'

export default {
  name: LOGIN.name,
  data () {
    return {
      logining: false,
      loginTips: '',
      form: { username: '', password: '' },
      pwdVisiable: false
    }
  },
  computed: {
    passwordCss () {
      return this.pwdVisiable ? { type: 'text', icon: 'eye' } : { type: 'password', icon: 'eye-slash' }
    }
  },
  methods: {
    login () {
      if (!this.logining) this.beforeLogin()
    },
    beforeLogin () {
      this.logining = true
      this.clearCookie()
      this.validateUser()
      if (this.loginTips.length > 0) {
        this.logining = false
        return false
      }
      this.axios.post(API.LOGIN, {
        username: this.form.username,
        password: md5(this.form.password)
      }).then(response => {
        if (response.Status) {
          this.loginSuccess(response)
        } else {
          this.loginFail(response)
        }
      })
    },
    validateUser () {
      if (this.form.username === '') this.loginTips = '请输入用户名'
      else if (this.form.password === '') this.loginTips = '请输入密码'
      else this.loginTips = ''
    },
    loginSuccess (response) {
      $(document).unbind('keydown') // 删除键盘事件
      if (this.form.markUsername) {
        this.$cookies.set('username', this.form.username, 604800)
      } else {
        this.$cookies.set('username', '')
      }
      this.$store.commit(SET_TOKEN, { access_token: response.Data })
      this.$router.push(INDEX)
    },
    loginFail (response) {
      this.logining = false
      if (response) {
        this.loginTips = response.Message
      }
    },
    clearCookie () {
      this.$store.commit(CLEAR_TOKEN)
      this.$store.commit(CLEAR_LOGIN)
    }
  },
  created () {
    this.clearCookie()
    const username = this.$cookies.get('username')
    this.form.username = username === null ? '' : username
    this.form.markUsername = this.form.username.length > 0
  },
  mounted () {
    var self = this
    $(document).keydown((event) => {
      switch (event.keyCode) {
        case 13: self.login(); break
      }
    })
  }
}
</script>

<style lang="scss" scoped>
$primary-color: #409EFF;
$primary-hover-color: rgba(102, 208, 208, .8);
$form-width: 260px;

.header {
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);

  img {
    margin-left: 150px;
    width: 100px;
    height: 40px;
  }

  .el-divider {
    height: 30px;
  }

  .title {
    font-size: 28px;
  }
}

.content {
  padding: 0;
  background: rgba(250, 250, 250, 1);

  .bg {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: url("../../assets/img/login-background.png") no-repeat;
    background-size: cover;
    -moz-background-size: cover;

    .info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        color: #666;
        font-size: 24px;
      }

      img {
        width: 300px;
      }
    }
  }

  .login-box {
    display: flex;
    flex-direction: column;
    padding: .75rem;
    margin: 50px 150px 50px 0;
    width: 450px;
    height: 500px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);

    .title {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 26px;
      color: $primary-color;
      margin: 50px 0;

      label {
        width: 80px;
        text-align: center;
      }

      .welcome {
        font-size: 14px;
        color: #DDDDDD;
      }
    }

    /deep/ .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      .forget-pwd {
        color: $primary-color;

        &:hover {
          color: $primary-color;
        }
      }

      .tips {
        width: $form-width;
        margin-bottom: 10px;
        padding-left: 5px;
        text-align: left;
        font-size: .75rem;
        color: #666;

        .fa-minus-circle {
          color: #F56C6C;
          padding-right: 5px;
        }
      }

      .el-input {
        margin-bottom: 20px;
        background-color: transparent;
        width: $form-width;
        border-bottom: 1px solid #F4F4F4;

        input {
          color: #666;
          background-color: transparent;
          border: none;
          height: 42px;
          padding: 0 15px 0 5px;
        }

        .el-input-group__prepend {
          background: transparent;
          border: none;
          color: $primary-color;
          padding: 0;
        }

        svg {
          width: 20px;
        }
      }

      .type-box {
        width: $form-width;
        display: flex;
        justify-content: space-between;
        font-size: .75rem;

        .el-checkbox__label {
          font-size: .75rem;
        }

        .el-button {
          padding: 0;
        }
      }

      .login-button {
        margin-top: 50px;
        font-size: 1rem !important;
        color: #fff;
        border: none;
        width: 260px;
        height: 42px;
      }
    }
  }
}

.buttom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: auto;
  background: rgba(250, 250, 250, 1);

  .contact {
    display: flex;
    flex-wrap: wrap;
    padding-top: 35px;
    width: 80%;
    border-top: 1px solid #DCDFE6;

    .el-link {
      padding: 0 30px 0 0;
      font-size: 1rem;
      color: #909399;

      &:hover {
        color: $primary-hover-color;
        transition: .3s;
      }
    }
  }

  .copy-right {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 10px;
    font-size: 14px;
    color: #606266;

    .copy {
      margin-top: 10px;

      .el-link {
        font-size: .75rem;
        color: #909399;
      }

      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
